import Swiper from "swiper/bundle"

export class Carousel
{
	carousels = document.querySelectorAll('[data-s~="Carousel"]')
	buttonsPlay = document.querySelectorAll('[data-s~="Carousel:play"]')
	buttonsStop = document.querySelectorAll('[data-s~="Carousel:stop"]')
	swipers = []

	constructor()
	{
		this.config = {
			main: {
				direction: "horizontal",
				loop: false,
				speed: 1000,
				autoplay: {
					delay: 4000,
					disableOnInteraction: true
				},
				slidesPerView: 1,
				pagination: {
					type: "progressbar"
				},
				navigation: {
					prevEl: null,
					nextEl: null
				},
			},
			splash: {
				autoplay: {
					delay: 6000,
				},
				breakpoints: {
					0: {
						slidesPerView: 1,
					},
				}
			},
		}
	}

	run()
	{
		this.carousels.forEach(e => {
			let uuid = e.getAttribute("data-uuid")

			let settings

			if (e.hasAttribute("data-config")) {
				let config = e.getAttribute("data-config")

				settings = {
					...this.config.main,
					...this.config[config]
				}
			} else {
				settings = {
					...this.config.main
				}
			}

			settings.pagination.el = '[data-s~="Carousel:pagination"][data-uuid~="'+uuid+'"]'
			settings.navigation.prevEl = '[data-s~="Carousel:previous"][data-uuid~="'+uuid+'"]'
			settings.navigation.nextEl = '[data-s~="Carousel:next"][data-uuid~="'+uuid+'"]'

			this.swipers[uuid] = new Swiper(e, settings)
		})
	}

	play(e)
	{
		let uuid = e.getAttribute("data-uuid")

		let gallery = document.querySelector('[data-s~="Carousel"][data-uuid~="'+uuid+'"]')
		let buttonPause = document.querySelector('[data-s~="Carousel:stop"][data-uuid~="'+uuid+'"]')

		gallery.swiper.autoplay.start()

		e.style.display = "none"
		buttonPause.style.display = "flex"
	}

	stop(e)
	{
		let uuid = e.getAttribute("data-uuid")

		let gallery = document.querySelector('[data-s~="Carousel"][data-uuid~="'+uuid+'"]')
		let buttonPlay = document.querySelector('[data-s~="Carousel:play"][data-uuid~="'+uuid+'"]')

		gallery.swiper.autoplay.stop()

		e.style.display = "none"
		buttonPlay.style.display = "flex"
	}

	watch()
	{
		this.buttonsPlay.forEach(e => {
			e.addEventListener("click", () => {
				this.play(e)
			})
		})
		this.buttonsStop.forEach(e => {
			e.addEventListener("click", () => {
				this.stop(e)
			})
		})
	}
}
